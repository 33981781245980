* {
  margin: 0;
}
body {
  scroll-behavior: smooth;
  font-family: "albert-regular";
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}
@font-face {
  font-family: "albert-light";
  src: url(../fonts/AlbertSans-Light.woff);
}
@font-face {
  font-family: "albert-thin";
  src: url(../fonts/AlbertSans-Thin.woff);
}
@font-face {
  font-family: "albert-regular";
  src: url(../fonts/AlbertSans-Regular.woff);
}
@font-face {
  font-family: "albert-medium";
  src: url(../fonts/AlbertSans-Medium.woff);
}
@font-face {
  font-family: "albert-extra-light";
  src: url(../fonts/AlbertSans-ExtraLight.woff);
}

ul {
  list-style: none;
}
::-webkit-scrollbar {
  display: none;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  display: none;
}
a {
  text-decoration: none;
  color: unset;
}
.wrapper {
  width: 70%;
  margin: 0 auto;
}
img {
  display: block;
  width: 100%;
}
#background-video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  /* position: fixed; */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
#mob-video {
  width: 100%;
  height: 100vh;
  display: none;
  object-fit: cover;
  /* position: fixed; */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
:root {
  --blue: #007bb6;
  --black: #373737;
  --meroon: #bc1545;
}
.card {
  width: 200px;
  height: 300px;
  background-color: #ccc;
  margin: 10px;
  float: left;
  position: relative;
  transition: transform 0.3s;
  overflow: hidden;
}
.card.hovered {
  transform: translateY(-60%);
  z-index: 1;
}

.card.hovered ~ .card {
  transform: translateY(-40%);
}

.card.hovered ~ .card ~ .card {
  transform: translateY(-30%);
}

.card.hovered ~ .card ~ .card ~ .card {
  transform: translateY(-20%);
}
.slick-prev:before {
  content: url(../../assets/images/icons/left-arrow.svg) !important;
  opacity: 1 !important;
}
.slick-next:before {
  opacity: 1 !important;
  content: url(../../assets/images/icons/right-arrow.svg) !important;
}
.slick-prev {
  left: 0px !important;
  top: 104% !important;
}
.slick-next {
  right: 0 !important;
  top: 104% !important;
}

/* --------------arrow animation-------------- */
.arrows {
  width: 60px;
  height: 72px;
  position: absolute;
  left: 50%;
  cursor: pointer;
  margin-left: -30px;
  bottom: 20px;
}

.arrows path {
  stroke: #ffffff;
  fill: transparent;
  stroke-width: 2px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes arrow /*Safari and Chrome*/ {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s; /* Safari 和 Chrome */
}
/* arrow css */

.arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: rotate(90deg);
  cursor: pointer;
}

.arrow span {
  display: block;
  width: 8px;
  height: 8px;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

/* responsive-------- */
@media all and (max-width: 1440px) {
  .wrapper {
    width: 80%;
  }
}
@media all and (max-width: 1280px) {
}
@media all and (max-width: 1080px) {
}
@media all and (max-width: 980px) {
}
@media all and (max-width: 768px) {
  .wrapper {
    width: 90%;
  }
}
@media all and (max-width: 480px) {
  #background-video {
    display: none;
  }
  #mob-video {
    display: block;
  }
}
